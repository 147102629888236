import {
    Avatar,
    Box,
    Button,
    Grid,
    CardHeader,
    CardMedia,
    Card,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    CardContent,
    CardActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import React, { Fragment, MouseEvent, useMemo, useRef, useState } from "react";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import { VCard } from "../../layout/Card/VCard";
import { useClassroom } from "../contexts/classroom.context";
import barchartImg from "../../../img/barchart.svg";
import Spinner from "../../layout/Spinner";
import { useHistory } from "react-router-dom";
import schoolClipArt from "../../../img/school-clip-art.jpg";
import { Edit, MoreVert, Delete, RestoreFromTrash } from "@material-ui/icons";
import { DestructiveButton, PrimaryButton, SecondaryButton } from "../../layout/Button/Button";
import { useMobile } from "../../../hooks/useMobile";
import { useSetTourState } from "../../tour/Store";
import { update } from "../../../client/api/classroom";
import { useAlert } from "../../../contexts/alert.context";

export const Classrooms = (): JSX.Element => {
    const { allClassrooms, loading, listClassrooms, setClassroom, clearProvider } = useClassroom();

    const { isMobile, isTablet } = useMobile();
    const history = useHistory();
    const setTourState = useSetTourState();
    const { setAlert } = useAlert();
    const [classroomId, setClassroomId] = useState<number>(0);

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchor);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [viewDeleted, setViewDeleted] = useState(false);

    const createRef = useRef<HTMLButtonElement | null>(null);

    useMemo(() => {
        listClassrooms();
    }, []);

    useMemo(() => {
        if (allClassrooms?.length > 0) {
            history.replace({ search: "" });
        } else {
            history.replace({ search: "?empty" });
        }
    }, [allClassrooms]);

    useMemo(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("classroomCreate", createRef) }));
    }, [createRef]);

    if (loading) {
        return <Spinner />;
    }

    const handleCreateClicked = () => {
        clearProvider();
        history.push("/classrooms/add");
    };

    return (
        <>
            {!isTablet && isMobile && <div style={{ paddingTop: "64px" }} />}
            {!loading && allClassrooms && allClassrooms.length && allClassrooms.length > 0 ? (
                <Box p={!isTablet && isMobile ? 2 : 4} pt={4}>
                    <Box display={"flex"} justifyContent="space-between" mb={3}>
                        <Typography variant="h2">Classrooms</Typography>
                        <Button
                            ref={createRef}
                            id="createClassroom"
                            variant="contained"
                            color="primary"
                            onClick={handleCreateClicked}
                        >
                            Create Classroom
                        </Button>
                    </Box>
                    <Grid container spacing={3} id="classroomsList">
                        {allClassrooms
                            .filter((clsRm) => (viewDeleted ? clsRm.deleted : !clsRm.deleted))
                            .map((classroom, index) => {
                                const numberTeachers =
                                    (classroom.Teachers?.length && classroom.Teachers?.length + 1) || 1;

                                return (
                                    <Grid item key={classroom.id} xs={12}>
                                        <Card
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar>
                                                        {classroom.PrimaryTeacher?.firstName?.charAt(0)}
                                                        {classroom.PrimaryTeacher?.lastName?.charAt(0)}
                                                    </Avatar>
                                                }
                                                action={
                                                    <>
                                                        <IconButton
                                                            id={index === 0 ? "classroom-settings" : undefined}
                                                            aria-controls="classroom-settings"
                                                            onClick={(event: MouseEvent<HTMLElement>) => {
                                                                setMenuAnchor(event.currentTarget);
                                                                setClassroom(classroom);
                                                                // console.log(classroom);
                                                                setClassroomId(classroom.id);
                                                            }}
                                                        >
                                                            <MoreVert />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={menuAnchor}
                                                            open={openMenu}
                                                            getContentAnchorEl={undefined}
                                                            anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                                            onClose={() => {
                                                                setMenuAnchor(null);
                                                                // console.log(classroom);
                                                            }}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    // console.log(classroom);
                                                                    history.push("/classrooms/add");
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <Edit />
                                                                </ListItemIcon>
                                                                <Typography>Edit details</Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setConfirmDelete(true);
                                                                    // console.log(classroom);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    {classroom.deleted ? (
                                                                        <RestoreFromTrash />
                                                                    ) : (
                                                                        <Delete />
                                                                    )}
                                                                </ListItemIcon>
                                                                <Typography>
                                                                    {classroom.deleted
                                                                        ? "Unarchive Classroom"
                                                                        : "Archive Classroom"}
                                                                </Typography>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                }
                                                title={classroom.name}
                                                subheader={`${classroom.PrimaryTeacher?.firstName} ${classroom.PrimaryTeacher?.lastName}`}
                                            />
                                            <CardMedia
                                                style={{ height: 200 }}
                                                image={schoolClipArt}
                                                title={"Image by pikisuperstar on Freepik"}
                                            />
                                            <CardContent>
                                                <Typography component="p">{classroom.description}</Typography>
                                            </CardContent>
                                            <CardActions style={{ justifyContent: "space-between", marginTop: "auto" }}>
                                                <div>
                                                    <Typography component="p">
                                                        {classroom.Students?.length && classroom.Students?.length > 0
                                                            ? classroom.Students.length
                                                            : "0"}{" "}
                                                        Students
                                                    </Typography>
                                                    <Typography>{numberTeachers} Staff</Typography>
                                                </div>
                                                <PrimaryButton
                                                    id={index === 0 ? "goToClassroom" : undefined}
                                                    onClick={() => history.push(`/classrooms/view/${classroom.id}`)}
                                                >
                                                    View Class
                                                </PrimaryButton>
                                            </CardActions>
                                        </Card>
                                        <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                                            <DialogTitle>
                                                {classroom?.deleted ? "Restore" : "Delete"} Classroom?
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>{`Are you sure you want to ${
                                                    classroom?.deleted ? "restore" : "delete"
                                                } this classroom?`}</DialogContentText>
                                                {classroom?.deleted ? (
                                                    <DialogContentText>
                                                        If you restore this classroom it will be available in the
                                                        classrooms list again. Any data will also be restored. If you
                                                        choose to delete this classroom again, it will be archived for
                                                        30 days then it will be deleted permanently
                                                    </DialogContentText>
                                                ) : (
                                                    <DialogContentText>
                                                        If you delete this classroom it will be archived for 30 days.
                                                        You can restore the classroom at any time before 30 days. After
                                                        30 days the classroom will be permanently deleted.
                                                    </DialogContentText>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <DestructiveButton
                                                    startIcon={classroom?.deleted ? <RestoreFromTrash /> : <Delete />}
                                                    onClick={() => {
                                                        if (classroomId) {
                                                            if (classroom.deleted) {
                                                                update(classroomId, { deleted: false }).then(() => {
                                                                    setAlert(
                                                                        "Classroom successfully restored",
                                                                        "success",
                                                                    );
                                                                    history.push(`/classrooms/view/${classroomId}`);
                                                                });
                                                            } else {
                                                                update(classroomId, { deleted: true }).then(() => {
                                                                    setAlert(
                                                                        "Classroom successfully deleted. Go to the status drop down menu if you need to revisit any of your archived classrooms",
                                                                        "success",
                                                                    );
                                                                    setConfirmDelete(false);
                                                                    history.push(`/home`);
                                                                });
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {classroom?.deleted ? "Restore" : "Delete"} Classroom
                                                </DestructiveButton>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        setConfirmDelete(false);
                                                        //console.log(classroom);
                                                    }}
                                                >
                                                    Cancel
                                                </SecondaryButton>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Box m={3}>
                        <SecondaryButton fullWidth onClick={() => setViewDeleted(!viewDeleted)}>
                            {viewDeleted ? "View Active Classrooms" : "View Deleted Classrooms"}
                        </SecondaryButton>
                    </Box>
                </Box>
            ) : (
                <Box m="auto" p={4}>
                    <VCard style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <div id="classroomsListEmpty">
                            <SimpleBlankSlate
                                header={"Classrooms"}
                                image={<img src={barchartImg} />}
                                subHeader={`You haven't been added to any classrooms`}
                            />
                        </div>
                        <Button
                            ref={createRef}
                            variant="text"
                            color="primary"
                            onClick={handleCreateClicked}
                            id="createFirstClassroom"
                        >
                            Create Classroom
                        </Button>
                    </VCard>
                </Box>
            )}
        </>
    );
};
