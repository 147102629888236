import { Box, Grid, ImageList, ImageListItem, Typography } from "@material-ui/core";
import React from "react";
import { useAuth } from "../../contexts/auth.context";
import Spinner from "../layout/Spinner";
import { HasPermission } from "../auth/HasPermission";
import { ActiveProgrammesHeadlineWidget } from "./Widgets/Headlines/ActiveProgrammesHeadlineWidget";
import { ParticipantsHeadlineWidget } from "./Widgets/Headlines/ParticipantsHeadlineWidget";
import { AttendanceHeadlineWidget } from "./Widgets/Headlines/AttendanceHeadlineWidget";
import { NewestProgrammeHeadlineWidget } from "./Widgets/Headlines/NewestProgrammeHeadlineWidget";
import { GenderPieWidget } from "./Widgets/PieCharts/GenderPieWidget";
import { AgePieWidget } from "./Widgets/PieCharts/AgePieWidget";
import { ActiveProgrammeSessionsBarWidget } from "./Widgets/StackedBarCharts/ActiveProgrammeSessionsBarWidget";
import { FetchProvider } from "../../core/components/crud/contexts/fetch.context";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { getStats } from "../../client/api/users";
import { UpcomingSessionsDrawer } from "./Widgets/Drawers/UpcomingSessionsDrawer";
// import { NoProgrammesAlert } from "./Widgets/Alerts/NoProgrammesAlert";
import { useMobile } from "../../hooks/useMobile";
import { StoryHeadlineWidget } from "./Widgets/Headlines/story/StoryHeadlineWidget";
import { CollectionHeadlineWidget } from "./Widgets/Headlines/story/CollectionHeadlineWidget";
import { CurriculumHeadlineWidget } from "./Widgets/Headlines/story/CurriculumHeadlineWidget";
import { ReviewedStoriesHeadlineWidget } from "./Widgets/Headlines/story/ReviewedStoriesHeadlineWidget";
import { PublishedStoriesHeadlineWidget } from "./Widgets/Headlines/story/PublishedStoriesHeadlineWidget";
import { TutorialVideosWidget } from "./Widgets/Headlines/help/TutorialVideosWidget";
import { ClassroomWidget } from "./Widgets/Headlines/help/ClassroomsWidget";
import { VisitPromoSiteWidget } from "./Widgets/Headlines/help/VisitPromoSiteWidget";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { ContactUsWidget } from "./Widgets/Headlines/help/ContactUsWidget";
import { ExploreWidget } from "./Widgets/Headlines/ExploreWidget";

const Dashboard = (): JSX.Element => {
    const { user } = useAuth();
    const { isTablet, isMobile } = useMobile();

    const isFacilitator = user?.Role.name === "Facilitator";
    const isStory = user?.Role.name.includes("Story");
    const isStoryAdmin = user?.Role.name === "StoryAdmin";
    const isStoryPublisher = user?.Role.name === "StoryPublisher";

    return (
        <>
            <Box display={"flex"} height={1}>
                <HasPermission permission="DashboardView">
                    <FetchProvider<ActiveProgramStats> noun="stats" getItem={getStats} fetchOnload>
                        <Box p={!isTablet && isMobile ? 2 : 4} width={1} className="dashboardContainer">
                            {!user && <Spinner />}
                            {!!user && (
                                <>
                                    <Box mb={4} mt={!isTablet && isMobile ? 2 : 0}>
                                        {!isTablet && isMobile && <div style={{ paddingTop: "64px" }} />}
                                        <Typography variant="h2" color="textPrimary" align="left">
                                            Hello {user.firstName}
                                        </Typography>
                                    </Box>
                                    {/* <NoProgrammesAlert /> */}
                                    {/* {!isStory && <LiveSessionBanner />} */}

                                    <Box mb={1}>
                                        <Typography
                                            variant="h3"
                                            color="textPrimary"
                                            align="left"
                                            style={{ paddingRight: verbalTheme.spacing(3) }}
                                        >
                                            Let&apos;s get you started
                                        </Typography>
                                    </Box>
                                    <Box pb={2}>
                                        <ImageList
                                            id="get-started"
                                            gap={10}
                                            style={
                                                isMobile
                                                    ? { flexWrap: "nowrap", transform: "translateZ(0)" }
                                                    : undefined
                                            }
                                            //className={isMobile ? classes.imageList : ""}
                                            cols={isTablet ? 3.5 : isMobile ? 2.5 : 4}
                                        >
                                            <ImageListItem id="tutorial" className="dashTutorialsWidget">
                                                <TutorialVideosWidget minHeight="200" />
                                            </ImageListItem>

                                            {user?.isSchool ? (
                                                <ImageListItem id="visit-website" className="dashClassroomsWidget">
                                                    <ClassroomWidget minHeight="200" />
                                                </ImageListItem>
                                            ) : (
                                                <ImageListItem id="visit-website">
                                                    <VisitPromoSiteWidget minHeight="200" />
                                                </ImageListItem>
                                            )}

                                            {!isTablet && isMobile ? null : (
                                                <ImageListItem className="dashExploreWidget">
                                                    <ExploreWidget minHeight="200" />
                                                </ImageListItem>
                                            )}

                                            <ImageListItem id="contact-us" className="dashContactUsWidget">
                                                <ContactUsWidget minHeight="200" />
                                            </ImageListItem>
                                        </ImageList>
                                    </Box>

                                    <Box mb={2}>
                                        <Typography
                                            variant="h3"
                                            color="textPrimary"
                                            align="left"
                                            style={{ paddingRight: verbalTheme.spacing(3) }}
                                            className="dashActivityTitle"
                                        >
                                            Your Activity
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2} className="dashActivitySection">
                                        {isStoryAdmin ? (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                {" "}
                                                <ReviewedStoriesHeadlineWidget />{" "}
                                            </Grid>
                                        ) : null}

                                        {isStoryPublisher ? (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <PublishedStoriesHeadlineWidget />
                                            </Grid>
                                        ) : null}

                                        {isStory ? (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <StoryHeadlineWidget />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <ActiveProgrammesHeadlineWidget isFacilitator={isFacilitator} />
                                            </Grid>
                                        )}

                                        {isStory ? (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <CollectionHeadlineWidget />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <ParticipantsHeadlineWidget isFacilitator={isFacilitator} />
                                            </Grid>
                                        )}

                                        {isStory ? (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <CurriculumHeadlineWidget />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                {" "}
                                                <AttendanceHeadlineWidget isFacilitator={isFacilitator} />{" "}
                                            </Grid>
                                        )}

                                        {!isFacilitator && !isStory && (
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <NewestProgrammeHeadlineWidget />
                                            </Grid>
                                        )}

                                        {!isStory && <ActiveProgrammeSessionsBarWidget isFacilitator={isFacilitator} />}

                                        {!isFacilitator && !isStory && <GenderPieWidget />}

                                        {!isFacilitator && !isStory && <AgePieWidget />}
                                    </Grid>
                                </>
                            )}
                        </Box>
                        {!isMobile && !isStory && <UpcomingSessionsDrawer isFacilitator={isFacilitator} />}
                    </FetchProvider>
                </HasPermission>
            </Box>
        </>
    );
};

export default Dashboard;
