import React from "react";

export const GameActivities = (): JSX.Element => {
    return (
        <div>
            <iframe
                id="gameContainer"
                src="/VerbalWellbeingActivities/index.html"
                title="Verbal Wellbeing Activities"
                style={{
                    border: "none",
                    width: "100%",
                    height: "800px",
                    transformOrigin: "top left",
                    transform: "scale(1) translateX(0%) translateY(0%)",
                }}
            />
        </div>
    );
};
