import React, { useEffect } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { FormActions } from "../../../../../core/components/FormActions";
import { PrimaryButton, SecondaryButton } from "../../../../layout/Button/Button";
import { useStoryCollection } from "../../contexts/collection.context";
import { useHistory } from "react-router";
import Alert from "@material-ui/lab/Alert";
import { useStory } from "../../../../../hooks/useStory";
import { VPillPositive } from "../../../../../core/components/VPill";
import { useThemes } from "../../../../../hooks/useTheme";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";

export const CollectionStories = (): JSX.Element => {
    const { collection, submitStep, addCollectionWeeks, changeStep, clearProvider, details } = useStoryCollection();
    const { stories, listStories } = useStory();
    const initialValues = {
        weeks: Array.from({ length: details.numWeeks ?? 1 }).map((_item, index) => {
            return { order: index + 1, learningObjective: "", themeId: 0, storyId: 0 };
        }),
    };

    const history = useHistory();
    const { themes, getAllThemes } = useThemes();

    const addCallback = () => clearProvider();

    useEffect(() => {
        listStories();
    }, []);

    // get all themes for select list
    useEffect(() => {
        getAllThemes();
    }, []);

    const onSubmit = (values: {
        weeks: { order: number; learningObjective: string; themeId: number; storyId: number }[];
    }) => {
        if (collection) {
            submitStep(collection);
            const id = collection.id;
            addCollectionWeeks(collection.id, values.weeks).then(addCallback).catch(addCallback).finally(addCallback);
            history.push(`/collections/view/${id}`);
        }
    };

    return (
        <>
            <WizardHeading overline="Create a Story Collection" heading="Select collection stories" />
            <Grid container spacing={3}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={yup.object().shape({
                        weeks: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    order: yup.number().required("Order is required"),
                                    learningObjective: yup.string().required("Learning objective is required"),
                                    themeId: yup.number().required("Theme is required").min(1),
                                    //storyId: yup.number().required("Story is required").min(1),
                                }),
                            )
                            .min(1, "Weeks Required"),
                    })}
                >
                    {({ submitForm, values, handleChange, errors, touched }) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Please select the Theme, enter a learning objective and seect a story for each
                                        week of the programme
                                    </Alert>
                                </Grid>

                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={1}>Week</TableCell>
                                                    <TableCell>Learning Objective</TableCell>
                                                    <TableCell width={"25%"}>Theme</TableCell>
                                                    <TableCell width={"25%"}>Story</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FieldArray name="weeks">
                                                    {() =>
                                                        values.weeks.map((week, index) => (
                                                            <TableRow key={week.order}>
                                                                <TableCell align="right">{week.order}</TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        value={week.learningObjective}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                        }}
                                                                        error={
                                                                            !!touched?.weeks?.[index]
                                                                                ?.learningObjective &&
                                                                            !!{}.hasOwnProperty.call(
                                                                                errors?.weeks?.[index] || {},
                                                                                "learningObjective",
                                                                            )
                                                                        }
                                                                        variant="outlined"
                                                                        label="Learning Objective"
                                                                        name={`weeks.${index}.learningObjective`}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <InputLabel id="theme-label" required>
                                                                            Theme for this week
                                                                        </InputLabel>
                                                                        <Select
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label="Theme for this week"
                                                                            labelId="theme-label"
                                                                            name={`weeks.${index}.themeId`}
                                                                            onChange={handleChange}
                                                                            value={week.themeId}
                                                                            error={
                                                                                !!touched?.weeks?.[index]?.themeId &&
                                                                                !!{}.hasOwnProperty.call(
                                                                                    errors?.weeks?.[index] || {},
                                                                                    "themeId",
                                                                                )
                                                                            }
                                                                        >
                                                                            {themes.map((theme) => (
                                                                                <MenuItem
                                                                                    key={theme.id}
                                                                                    value={theme.id}
                                                                                >
                                                                                    <VPillPositive
                                                                                        size="small"
                                                                                        label={theme.name}
                                                                                    />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <InputLabel id="story-label" required>
                                                                            Story for this week
                                                                        </InputLabel>
                                                                        <Select
                                                                            variant="outlined"
                                                                            style={{
                                                                                textOverflow: "ellipsis",
                                                                                overflow: "hidden",
                                                                                whiteSpace: "pre",
                                                                                wordBreak: "break-all",
                                                                            }}
                                                                            label="Story for this week"
                                                                            labelId="story-label"
                                                                            name={`weeks.${index}.storyId`}
                                                                            onChange={handleChange}
                                                                            value={week.storyId}
                                                                            // error={
                                                                            //     !!touched?.weeks?.[index]?.storyId &&
                                                                            //     !!{}.hasOwnProperty.call(
                                                                            //         errors?.weeks?.[index] || {},
                                                                            //         "storyId",
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            {stories.map((story) => (
                                                                                <MenuItem
                                                                                    style={{
                                                                                        whiteSpace: "unset",
                                                                                        wordBreak: "break-word",
                                                                                    }}
                                                                                    key={story.id}
                                                                                    value={story.id}
                                                                                >
                                                                                    {story.storyName}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </FieldArray>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>

                                <Grid item xs={12}>
                                    <FormActions
                                        left={
                                            <SecondaryButton size="large" onClick={() => changeStep(1)}>
                                                Back
                                            </SecondaryButton>
                                        }
                                        right={
                                            <PrimaryButton size="large" onClick={() => submitForm()}>
                                                Continue
                                            </PrimaryButton>
                                        }
                                    />
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
};
