import { node } from "prop-types";
import React, { useContext, createContext, useCallback, useState, FC, ReactNode } from "react";
import {
    add,
    addMood,
    addTeacher,
    get,
    list,
    editClassroom,
    removeTeacher,
    addParticipant,
    updateThreshold,
    getThreshold,
    emailTeacherThreshold,
    resolveMood,
    getResolvedMoods,
    updateEmailNotification,
    getEmailNotification,
    emailAdminThreshold,
    emailAdminFlags,
    findAdmins,
} from "../../../client/api/classroom";
import { ClassroomType, MoodType, ParticipantToClassroomType } from "../../../models/modelTypes";
import { EmotionThreshold, EmailNotificationLog } from "common/build/prisma/client";
import { EmotionTimeframe } from "../entities/EmotionTimeframe";
import { EmailNotification } from "common/build/api-parameters/classroom";

interface AdminType {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

interface ClassroomContextI {
    allClassrooms: ClassroomType[];
    classroom?: ClassroomType;
    loading: boolean;
    error?: string;
    currentParticipant?: ParticipantToClassroomType;
    threshold?: EmotionThreshold;
    admins: AdminType[];
    resolvedMoods?: MoodType[];
    emailNotificationLog?: EmailNotificationLog;

    setCurrentParticipant: (p?: ParticipantToClassroomType) => void;
    setClassroom: (classroom?: ClassroomType) => void;
    getClassroom: (id: number) => Promise<void>;
    listClassrooms: () => Promise<void>;
    addClassroom: (details: Partial<ClassroomType>) => Promise<void>;
    addTeacherToClassroom: (id: number, userId: number) => Promise<void>;
    addStudentToClassroom: (
        id: number,
        student: {
            id: number;
            name?: string;
            email?: string | null;
            phoneNumber?: string | null;
            organisationId?: number;
        },
    ) => Promise<void>;
    removeTeacherFromClassroom: (id: number, userId: number) => Promise<void>;
    addStudentMood: (classroomId: number, moodDetails: Partial<MoodType>) => Promise<ClassroomType>;
    editClassroomState: (id: number, classroomId: number) => Promise<void>;
    getMoodToday: (s: ParticipantToClassroomType, type: string, sessionId: number | null) => MoodType | undefined;
    clearProvider: () => void;
    updateEmotionThreshold: (
        id: number,
        emotionCount: number,
        emotionFrequency: number,
        emotionTimeframe: EmotionTimeframe,
    ) => Promise<void>;
    getEmotionThreshold: (id: number) => Promise<void>;
    emailTeacherEmotionThreshold: (id: number, email: string, studentID: number, thresholdID: number) => Promise<void>;
    checkThreshold: (student: ParticipantToClassroomType, threshold: EmotionThreshold) => number;
    resolveNotification: (id: number, reason: string) => Promise<void>;
    getResolvedNotifications: (id: number) => Promise<void>;
    updateEmailNotificationStatus: (id: number, field: EmailNotification, flag: boolean) => Promise<void>;
    getEmailNotificationStatus: (id: number) => Promise<void>;
    emailAdminEmotionThreshold: (id: number, email: string, thresholdID: number, studentCount: number) => Promise<void>;
    getThresholdCrossCount: (classroom: ClassroomType, threshold: EmotionThreshold) => number;
    findClassAdmins: (id: number) => Promise<void>;
    emailAdminEmotionFlags: (id: number, adminEmail: string, teacherEmail: string) => Promise<void>;
}

const ClassroomContext = createContext({} as ClassroomContextI);

export const ClassroomProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [allClassrooms, setAllClassrooms] = useState<ClassroomType[]>([]);
    const [classroom, setClassroom] = useState<ClassroomType>();
    const [currentParticipant, setCurrentParticipant] = useState<ParticipantToClassroomType>();
    const [threshold, setThreshold] = useState<EmotionThreshold>();
    const [resolvedMoods, setResolvedMoods] = useState<MoodType[]>([]);
    const [emailNotificationLog, setEmailNotificationLog] = useState<EmailNotificationLog>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [admins, setAdmins] = useState<AdminType[]>([]);

    const getClassroom = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            get(id, {})
                .then((value) => {
                    setClassroom(value);
                    setError(undefined);
                    resolve();
                })
                .catch(() => {
                    setClassroom(undefined);
                    reject("Unable to get classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const listClassrooms = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            list({ take: 1000 })
                .then((values) => {
                    setAllClassrooms(values.items.sort((a, b) => a.id - b.id));
                    setError(undefined);
                    resolve();
                })
                .catch(() => {
                    setAllClassrooms([]);
                    reject("Unable to get all classrooms");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addClassroom = useCallback((details: Partial<ClassroomType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            add(details)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setClassroom(undefined);
                        reject("Unable to add classroom as response not OK");
                    }
                })
                .catch(() => {
                    setClassroom(undefined);
                    reject("Unable to add classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addTeacherToClassroom = useCallback((id: number, userId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            addTeacher(id, userId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        setAllClassrooms((prev) =>
                            [...prev.filter((cr) => cr.id !== classroomResponse.id), classroomResponse].sort(
                                (a, b) => a.id - b.id,
                            ),
                        );
                        resolve();
                    } else {
                        setError("Unable to add teacher because response not OK");
                        reject("Unable to add teacher because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to add teacher to classroom");
                    reject("Unable to add teacher to classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addStudentToClassroom = useCallback(
        (
            id: number,
            student: {
                id: number;
                name?: string;
                email?: string | null;
                phoneNumber?: string | null;
                organisationId?: number;
            },
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                addParticipant(id, student)
                    .then(async (response) => {
                        if (response.ok) {
                            const classroomResponse: ClassroomType = await response.json();
                            setClassroom(classroomResponse);
                            setAllClassrooms((prev) =>
                                [...prev.filter((cr) => cr.id !== classroomResponse.id), classroomResponse].sort(
                                    (a, b) => a.id - b.id,
                                ),
                            );
                            setError(undefined);
                            resolve();
                        } else {
                            setError("Unable to add student because response not OK");
                            reject("Unable to add student because response not OK");
                        }
                    })
                    .catch(() => {
                        setError("Unable to add student to classroom");
                        reject("Unable to add student to classroom");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const removeTeacherFromClassroom = useCallback((id: number, userId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            removeTeacher(id, userId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to remove teacher because response not OK");
                        reject("Unable to remove teacher because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to remove teacher");
                    reject("Unable to remove teacher");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addStudentMood = useCallback(
        (classroomId: number, moodDetails: Partial<MoodType>): Promise<ClassroomType> => {
            return new Promise((resolve, reject) => {
                setLoading(true);
                addMood(classroomId, moodDetails)
                    .then(async (response) => {
                        if (response.ok) {
                            const classroomResponse: ClassroomType = await response.json();
                            setClassroom(classroomResponse);
                            setError(undefined);
                            resolve(classroomResponse);
                        } else {
                            setError("Unable to add mood because response not OK");
                            reject("Unable to add mood because response not OK");
                        }
                    })
                    .catch(() => {
                        setError("Unable to add Mood");
                        reject("Unable to add mood");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const editClassroomState = useCallback((id: number, classroomId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            editClassroom(id, classroomId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to edit classroom details as response NOT OK");
                        reject("Unable to edit classroom details as response NOT OK");
                    }
                })
                .catch(() => {
                    setError("Unable to Edit Class");
                    reject("Unable to Edit Class");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const updateEmotionThreshold = useCallback(
        (id: number, emotionCount: number, emotionFrequency: number, emotionTimeframe: EmotionTimeframe) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                updateThreshold(id, emotionCount, emotionFrequency, emotionTimeframe)
                    .then(async (response) => {
                        if (response.ok) {
                            const thresholdResponse = await response.json();
                            setThreshold(thresholdResponse);
                            setError(undefined);
                            resolve();
                        } else {
                            setError("Unable to update emotion threshold because response not OK");
                            reject("Unable to update emotion threshold because response not OK");
                        }
                    })
                    .catch(() => {
                        setError("Unable to update emotion threshold");
                        reject("Unable to update emotion threshold");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const getEmotionThreshold = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getThreshold(id)
                .then(async (response) => {
                    if (response.ok) {
                        const thresholdResponse = await response.json();
                        setThreshold(thresholdResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to get emotion threshold because response not OK");
                        reject("Unable to get emotion threshold because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to get emotion threshold");
                    reject("Unable to get emotion threshold");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const emailTeacherEmotionThreshold = useCallback((id, email, studentID, thresholdID) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            emailTeacherThreshold(id, email, studentID, thresholdID)
                .then(async (response) => {
                    if (response.ok) {
                        resolve();
                    } else {
                        setError("Unable to send student monitor email because response not OK");
                        reject("Unable to send student monitor email because response not OK");
                    }
                })
                .catch((error) => {
                    console.error("Error in emailThreshold:", error);
                    setError("Unable to send student monitor email");
                    reject("Unable to send student monitor email");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const emailAdminEmotionThreshold = useCallback((id, email, thresholdID, studentCount) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            emailAdminThreshold(id, email, thresholdID, studentCount)
                .then(async (response) => {
                    if (response.ok) {
                        resolve();
                    } else {
                        setError("Unable to send admin threshold email because response not OK");
                        reject("Unable to send admin threshold email because response not OK");
                    }
                })
                .catch((error) => {
                    console.error("Error in emailAdminThreshold:", error);
                    setError("Unable to send admin threshold email");
                    reject("Unable to admin threshold email");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const emailAdminEmotionFlags = useCallback((id, adminEmail, teacherEmail) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            emailAdminFlags(id, adminEmail, teacherEmail)
                .then(async (response) => {
                    if (response.ok) {
                        resolve();
                    } else {
                        setError("Unable to send admin flags email because response not OK");
                        reject("Unable to send admin flags email because response not OK");
                    }
                })
                .catch((error) => {
                    console.error("Error in emailAdminFlags:", error);
                    setError("Unable to send admin flag email");
                    reject("Unable to admin flag email");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const checkThreshold = (student: ParticipantToClassroomType, threshold: EmotionThreshold) => {
        const { emotionCount, emotionFrequency, emotionTimeframe } = threshold;
        let negativeCount = 0;
        const negativeEmotions = ["Angry", "Sad", "Worried"];
        const timeframeStartDate = new Date();
        switch (emotionTimeframe) {
            case EmotionTimeframe.Days:
                timeframeStartDate.setDate(timeframeStartDate.getDate() - emotionFrequency);
                break;
            case EmotionTimeframe.Weeks:
                timeframeStartDate.setDate(timeframeStartDate.getDate() - emotionFrequency * 7);
                break;
            case EmotionTimeframe.Months:
                timeframeStartDate.setMonth(timeframeStartDate.getMonth() - emotionFrequency);
                break;
            default:
                console.error(`Unsupported timeframe: ${emotionTimeframe}`);
                throw new Error(`Unsupported timeframe: ${emotionTimeframe}`);
        }

        // Set to 00:00 to ensure full day calculation
        timeframeStartDate.setHours(0, 0, 0, 0);

        student?.Moods?.forEach((mood) => {
            const moodDate = new Date(mood.date);
            if (negativeEmotions.includes(mood.mood) && moodDate >= timeframeStartDate) {
                negativeCount++;
            }
        });

        return emotionCount - negativeCount;
    };

    const getMoodToday = (
        s: ParticipantToClassroomType,
        type: string,
        sessionId: number | null,
    ): MoodType | undefined => {
        const todayDate = new Date();
        const moodToday = s.Moods?.find((m) => {
            const moodDate = new Date(m.date);
            if (
                m.type === type &&
                (sessionId === null || m.sessionId === sessionId) &&
                moodDate?.getDate() === todayDate.getDate() &&
                moodDate?.getMonth() === todayDate.getMonth() &&
                moodDate?.getFullYear() === todayDate.getFullYear()
            ) {
                return m;
            } else {
                return undefined;
            }
        });

        return moodToday;
    };

    const resolveNotification = useCallback((id: number, reason: string) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            resolveMood(id, reason)
                .then(async (response) => {
                    if (response.ok) {
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to update mood because response not OK");
                        reject("Unable to update mood because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to update mood");
                    reject("Unable to update mood");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getResolvedNotifications = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getResolvedMoods(id)
                .then(async (response) => {
                    if (response.ok) {
                        const resolvedMoodResponse = await response.json();
                        setResolvedMoods(resolvedMoodResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to get resolved moods response not OK");
                        reject("Unable to get resolved moods because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to get resolved moods");
                    reject("Unable to get resolved moods");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const updateEmailNotificationStatus = useCallback((id: number, field: EmailNotification, flag: boolean) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            updateEmailNotification(id, field, flag)
                .then(async (response) => {
                    if (response.ok) {
                        const emailResponse = await response.json();
                        setEmailNotificationLog(emailResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to update email because response not OK");
                        reject("Unable to update email because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to update email threshold");
                    reject("Unable to update email threshold");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getEmailNotificationStatus = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getEmailNotification(id)
                .then(async (response) => {
                    if (response.ok) {
                        const emailResponse = await response.json();
                        setEmailNotificationLog(emailResponse);
                        setError(undefined);
                        resolve();
                    }
                })
                .catch(() => {
                    setError("Unable to get email");
                    reject("Unable to get email");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getThresholdCrossCount = (classroom: ClassroomType, threshold: EmotionThreshold) => {
        let studentCount = 0;
        const timeframeStartDate = new Date();
        timeframeStartDate.setDate(timeframeStartDate.getDate() - 7);
        if (classroom?.Students && threshold) {
            classroom?.Students.forEach((student) => {
                if (checkThreshold(student, threshold) < 1) {
                    studentCount++;
                }
            });
        }

        return studentCount;
    };

    const findClassAdmins = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            findAdmins(id)
                .then(async (response) => {
                    if (response.ok) {
                        const classAdmins = await response.json();
                        if (classAdmins && classAdmins.Teachers.length > 0) {
                            setAdmins(classAdmins.Teachers);
                        } else {
                            setAdmins([]);
                        }
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to get admins because response not OK");
                        reject("Unable to get admins because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to get admins");
                    reject("Unable to get admins");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const clearProvider = useCallback(() => {
        setClassroom(undefined);
        setError(undefined);
        setLoading(false);
    }, []);

    return (
        <ClassroomContext.Provider
            value={{
                allClassrooms,
                classroom,
                currentParticipant,
                loading,
                threshold,
                resolvedMoods,
                emailNotificationLog,
                admins,
                setCurrentParticipant,
                getClassroom,
                listClassrooms,
                addClassroom,
                addTeacherToClassroom,
                addStudentToClassroom,
                setClassroom,
                removeTeacherFromClassroom,
                addStudentMood,
                editClassroomState,
                getMoodToday,
                clearProvider,
                updateEmotionThreshold,
                getEmotionThreshold,
                emailTeacherEmotionThreshold,
                checkThreshold,
                resolveNotification,
                getResolvedNotifications,
                updateEmailNotificationStatus,
                getEmailNotificationStatus,
                emailAdminEmotionThreshold,
                getThresholdCrossCount,
                findClassAdmins,
                emailAdminEmotionFlags,
                error,
            }}
        >
            {children}
        </ClassroomContext.Provider>
    );
};

ClassroomProvider.propTypes = {
    children: node,
};

export const useClassroom = (): ClassroomContextI => useContext<ClassroomContextI>(ClassroomContext);
